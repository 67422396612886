import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Map from "../components/map"

import { Col, Image, Media } from "react-bootstrap"

import CocinasImage from "../images/reigas_imgs_1_cocinas.png"
import PerneriaImage from "../images/reigas_imgs_1_Pernos.png"
import TuberiaFerreteriaImage from "../images/reigas_imgs_ferrre.png"

import ReguladorImage from "../images/regulador_prod.png"
import CocinaImage from "../images/Cocina-prod.png"
import HornosImage from "../images/horno-prod.png"
import PernosImage from "../images/pernos-prod.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Perneria – Pernos, Tuercas y Arandelas" />
    <Col>
      <h1>Perneria – Pernos, Tuercas y Arandelas</h1>
      <br />
      <div className="productItem">
        <Media>
          <img
            // width={150}
            height={150}
            className="mr-3"
            src={PerneriaImage}
            alt="Pernos"
          />
          <Media.Body>
            <h2>Pernos, Tuercas y Arandelas</h2>
            <ul>
              <li>Pernos G2, G5, G8, G8.8, en rosca UNC y UNF.</li>
              <li>
                Pernos Allen C/Cil. y C/Ave. Inox. y Negra, Metrica y UNC.
              </li>
              <li>Pernos Inox. UNF Metrico y UNC.</li>

              <li>
                Tuercas: G2, G5, G8, G8.8, UNF y UNC, Negra, Galvanizada e
                Inoxidable.
              </li>

              <li>
                Arandelas: 1/8 a 1/2″, en Nacional, Galvanizada, Inoxidable y de
                Presión.
              </li>
            </ul>
            Consulte nuestro{" "}
            <a
              href="https://drive.google.com/open?id=1dvRkpmtyF3xu7ZWaSfUAv9hn2uwCDJJN"
              target="blank"
            >
              <b>Catálogo de Pernos, Tuercas y Arandelas</b>
            </a>
          </Media.Body>
        </Media>
      </div>
      <hr />
      <div className="productItem">
        <Media>
          <img
            // width={150}
            height={150}
            className="mr-3"
            src={PernosImage}
            alt="Tornos y Tirafondos"
          />
          <Media.Body>
            <h2>Tornillos y Tirafondos</h2>
            <p>Disponemos Tirafondos para madera, Metal y Autoperforantes.</p>
            <ul>
              <li>
                Ofrecemos nuestra amplia gama de Tornillos para Madera, DMF,
                Auto-roscantes en cabeza de pan y cabeza avellanado,
                Autoperforantes C/Pan, C/Sombrero, C/Avellanado.
              </li>
              <li>
                Tornillos Milimetricos C/Cilindrica, C/Avellanada, Tornillos de
                Estufa y Kits Escolares
              </li>
            </ul>
            Consulte nuestro{" "}
            <a
              href="https://drive.google.com/open?id=176Fj4sCMPz8_nLa-k4503148jJL8fsyh"
              target="blank"
            >
              <b>Catálogo de Tacos, Tirafondos y Tornillos.</b>
            </a>
          </Media.Body>
        </Media>
      </div>
      <br />
      <h2>Más Información</h2>

      <p>
        Consulte nuestro{" "}
        <a
          href="https://drive.google.com/open?id=1dvRkpmtyF3xu7ZWaSfUAv9hn2uwCDJJN"
          target="blank"
        >
          <b>Catálogo de Pernos, Tuercas y Arandelas</b>
        </a>{" "}
        y nuestro{" "}
        <a
          href="https://drive.google.com/open?id=176Fj4sCMPz8_nLa-k4503148jJL8fsyh"
          target="blank"
        >
          <b>Catálogo de Tacos, Tirafondos y Tornillos.</b>
        </a>
        .
      </p>
      <p>
        También puede revisar nuestro{" "}
        <a
          href="https://drive.google.com/open?id=11yYtqHOatXqN6mUY5jWUN7tk1OFrpaeG"
          target="blank"
        >
          <b>Catálogo completo de Representaciones Reinoso</b>
        </a>
        , o visitar nuestra sala de exhibición. Póngase en contacto con
        nosotros, gustosos en Atenderle.{" "}
      </p>

      <p>
        <ul>
           
          <li>
            <b>Telf:</b> +593 07-4076831
          </li>
          <li>
            <b>Cel:</b> +593 099 537 1857
          </li>
        </ul>
      </p>
    </Col>
  </Layout>
)

export default IndexPage
