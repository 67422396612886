// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Map = () => (
  <div className="mapouter">
    <div className="gmap_canvas">
      <iframe
        width="814"
        height="473"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=Representaciones%20Reinozo&t=&z=13&ie=UTF8&iwloc=&output=embed"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      ></iframe>
    </div>
    <style>
      {/* .mapouter{position:relative;text-align:right;height:473px;width:814px;}.gmap_canvas {overflow:hidden;background:none!important;height:473px;width:814px;} */}
    </style>
  </div>
)

export default Map
